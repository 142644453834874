<template>
    <div class="flex flex-col gap-6 justify-between max-h-full flex-1 overflow-y-auto overflow-x-hidden">
        <Card title="Assigned Users" titleClass="text-white-text">
            <div v-if="loadingStates.fetchingDetails" class="flex flex-1 items-center justify-center py-14">
                <Loader />
            </div>

            <div v-else class="flex-1 w-full p-4 overflow-y-auto scroll-bar flex">
                <List :showTitle="false" :listItems="getUserList" :showSelectAll="true" :showSearch="true" @handleSelect="$emit('selectAllUsers', $event)" @handleFilterResult="handleFilterResult($event)">
                    <template #listheader>
                        <div class="px-4 py-1 grid text-gray-600 text-sm font-semibold" :class="`grid-cols-${userHeaderList.length} ${userHeaderList.length > 1 && 'tenant_element' }` " >
                            <span v-for="header in userHeaderList" :key="header.name"> {{header.name}}</span>
                        </div>
                    </template>
                    <template #item="{ item }">
                        <AnalystListItem :key="item.id" :analyst="item"  @userAssign="$emit('userAssign', $event)" />
                    </template>
                </List>
            </div>
        </Card>
        <div class="flex items-center justify-end">
            <Button
                v-if="checkPermission('license.edit')"
                :disabled="disabledBUtton  || loadingStates.submit"
                @click="$emit('assignTenantUser')"
                type="primary"
                text="Save"
            />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Card from "@shared/components/card";
import AnalystListItem from "@shared/components/license-pack/client-analyst-list";
import Loader from "@shared/loader";
import List from "@shared/components/lists";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "assign-analyst-card",
    components: {
        Button,
        Card,
        AnalystListItem,
        Loader,
        List,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        getUserList: {
            type: Array,
            default: () => [],
        },
        userHeaderList:{
            type:Array,
            default: () => []
        },
        disabledBUtton: {
            type:Boolean,
        }
    },

    computed: {
        isSaveButtonDisabled() {
           return !this.getUserList.some((tenant) => tenant.checked);
        },
    },

    methods: {
        checkPermission,
        handleFilterResult(event) {
            this.$emit("handleFilterResult", { data: event, type: "users" });
        },
    },
};
</script>

<style lang="scss" scoped>

.tenant_element span:last-child{
    text-align: right;
}

</style>
